import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import store from 'storeConfig';
import { updateModalContent } from 'Actions/modalContentActions'
import {Helmet} from "react-helmet";
import ModalOverlay from 'Components/UtilityComponents/ModalOverlay/ModalOverlay'
import Dashboard from 'Components/Dashboard/Dashboard'

import 'Styles/shared.scss';
import ClassDetails from 'Components/ClassDetails/ClassDetails';
import Nav from 'Components/Nav/Nav';
import StudentEntry from 'Components/StudentEntry/StudentEntry';
import Auth from 'Components/Auth/Auth';
import NavSynod from 'Components/Synod/NavSynod'
import Logout from 'Components/Auth/Logout';
import Profile from 'Components/Profile/Profile';
import Calendar from 'Components/Calendar/Calendar';
import { useSelector } from 'react-redux';
import escriliFavicon from './Assets/favicons/favicon_escribli.png'
import faithFavicon from './Assets/favicons/favicon_faith.ico'
import DashSynod from 'Components/Synod/DashSynod/DashSynod';

function App() {

  const location = useLocation()
  const t = localStorage.getItem('t')
  const [ navHidden, setNavHidden ] = useState(false)
  const siteTitle = useSelector(state => state.appTitle);
  const userType = useSelector(state => state.userType);

  const toggleModal = ( vis, component, chat ) => {
    store.dispatch(updateModalContent({vis, component, chat })) };

  useEffect(()=>{

    store.dispatch(updateModalContent({vis:false, component:null}))
    localStorage.removeItem('chatModal')
    
    if(location.pathname==='/write'){
      setNavHidden(true)
    }else{
      setNavHidden(false)
    }

  },[location])

  useEffect(() => {

    const appHeight = () => {
      document.documentElement.style.setProperty("--viewportHeight", `${window.innerHeight}px`);
      document.documentElement.style.setProperty("--viewportMinus", `${window.innerHeight-60}px`);
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    // eslint-disable-next-line
  },[])

  return !t ? <Auth /> : (

      <>

        <Helmet>

        <title>{siteTitle}</title>

        <link 
          rel="icon" 
          type="image/x-icon"
          href={
          siteTitle === 'Escribli' ?  escriliFavicon : faithFavicon } />

        </Helmet>

      {


      
      userType === "Synod" ?

        <div className={"synodWrapper"}>

          <Switch>
            <Route exact path = "/dashboard" component={()=> <NavSynod dash />}/>
            <Route path = "/write" component={()=> <NavSynod />}/>
          </Switch>

          <Switch>
            <Route exact path = "/dashboard" component={()=> <DashSynod />}/>
            <Route path={"/write"} component={()=> <StudentEntry synod toggleModal={toggleModal}/> }/>
          </Switch>
        </div>

      :
    

      <>
       { window.location.pathname!=='/logout' && <Nav toggleModal={toggleModal} />}

        
        <div id={"bodyWrapper"} className={`bodyWrapper ${navHidden && 'fullHeight'}`}>


          <Switch>

            <Route exact path="/" component={()=> <Redirect to={"/dashboard"}/> } />

            <Route exact path="/dashboard" component={Dashboard}/>

            <Route path={"/class/:classId"} component={()=> 
              <ClassDetails
                toggleModal={toggleModal}/> }/>

            <Route path={"/write"} component={()=> 
              <StudentEntry
                toggleModal={toggleModal}/> }/>

            <Route path={"/profile"} component={()=> 
              <Profile
                toggleModal={toggleModal}/> }/>

            <Route path={"/calendar"} component={()=> 
              <Calendar
                toggleModal={toggleModal}/> }/>


            <Route exact path="/logout" component={Logout} />
      
            <Route  path="/login" component={Auth} />

          </Switch>

        
        
        </div>
      </>
    }

    

    <ModalOverlay synod={userType=="Synod"} closeModal = {()=> toggleModal(false, null) }/> 
    </>
  );
}

export default App;
